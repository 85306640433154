.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.CalendarDay .Ambler__CalendarDayInner {
  position: relative;
  height: 100%;
  width: 100%;

  padding: 6px;
}
.CalendarDay .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;

  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;  
}
.CalendarDay:active .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  outline: 0
}
.CalendarDay__defaultCursor .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  cursor: default
}
.CalendarDay__default .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  border-radius: 50%;
  color: #0c2344;
  background: #fff
}
.CalendarDay__default:hover .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  background: #f7fcfd;
}

.CalendarDay__selected_span .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  background: #e6fffd;
  color: #63748b
}
.CalendarDay__selected_span:active .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__selected_span:hover .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  background: #f7fcfd;
}

.CalendarDay__selected .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__selected:active .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__selected:hover .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  background: #3bbfb7;
  color: #fff
}

.CalendarDay__hovered_span .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__hovered_span:hover .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  background: #E5F7FE;
  color: #63748b
}
.CalendarDay__blocked_calendar .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__blocked_calendar:active .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__blocked_calendar:hover .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__blocked_out_of_range:active .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content,
.CalendarDay__blocked_out_of_range:hover .Ambler__CalendarDayInner .Ambler__CalendarDayInner__content {
  background: #fff;
  color: #b0c0d7
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #0c2344;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  padding: 16px 0px 43px 0px;
  font-weight: 700;
  text-transform: capitalize;
  caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 0 24px;
  position: absolute;
  width: 100%;
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}
.DayPicker {
  position: relative;
  text-align: left
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #63748b;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}